import * as React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const Featured = () => {
   return (
      <Container className='section mt-2 pt-1'>
         <Row>
            <Col xs={10} className='m-auto '>
               <h2 className='title'>Featured topics</h2>
            </Col>
         </Row>
         <Row>
            <Col xs={10} className='m-auto'>
               <Container>
                  <Row className='justify-content-lg-between'>

                     <Col xs={12} lg={4} className='light-grey featured-topic mb-3 mb-lg-0'>
                        <a className='home-card d-flex flex-column justify-content-between' href='/about-us'>
                           <div>
                              <h3>We Are All in This Together</h3>
                              <p className='title-one-line'>
                              Medicine alone won’t end the HIV epidemic. Get to know why it’ll take 
                              all of us working together, supporting the HIV community on all levels, 
                              and breaking down barriers, wherever they are to help end the HIV epidemic.
                              </p>
                           </div>
                           
                           <span className='read-more '>
                              <span className='font-heavy'>Learn more</span>
                              &nbsp;&nbsp;&nbsp;<i className='fa fa-chevron-right '></i>
                           </span>
                        </a>
                     </Col>

                     
                     <Col xs={12} lg={4} className='light-grey featured-topic mb-3 mb-lg-0'>
                        <a className='home-card d-flex flex-column justify-content-between' href='/landscape/hiv-rapid-start'>
                           <div>
                              <h3>Rapid Start Initiation: Starting HIV Treatment As Soon As Possible</h3>
                              <p className=''>
                              Discover why Rapid Start initiation is recommended by the US Department of Health and Human Services. Also, explore a case study on how Rapid Start is implemented in a Latinx community in Southern Texas.
                              </p>
                           </div>
                           
                           <span className='read-more '>
                              <span className='font-heavy'>Learn more</span>
                              &nbsp;&nbsp;&nbsp;<i className='fa fa-chevron-right '></i>
                           </span>
                        </a>
                     </Col>

                     <Col xs={12} lg={4} className='light-grey featured-topic mb-3 mb-lg-0'>
                        <a className='home-card d-flex flex-column justify-content-between' href='/deeper-dive/kayla-quimbley-video/'>
                           <div>
                              <h3>
                                 From Poetry to Presidents
                              </h3>
                              <p className='title-one-line'>
                                 Kayla Quimbley—the youngest member of the Presidential Advisory Council on HIV/AIDS—is shifting perspectives on what it means to live with HIV. Check out a video of her live performance of her poem, "Thriving with HIV," from Gilead's plenary session at USCHA 2023.

                              </p>
                           </div>
                           
                           <span className='read-more '>
                              <span className='font-heavy' href='/kayla-quimbley' >
                                 Watch video
                              </span>
                              &nbsp;&nbsp;&nbsp;<i className='fa fa-chevron-right '></i>
                           </span>
                        </a>
                     </Col>

                  </Row>
                  <Row>
                     <a href='/deeper-dive/content-index/' className='see-more mt-4'>
                        <span className='font-heavy'>See more content</span>{' '}
                        <i className='fa fa-chevron-right '></i>
                     </a>
                  </Row>
               </Container>
            </Col>
         </Row>
      </Container>
   );
};

export default Featured;
