import * as React from "react";
import { Component } from "react";
import Carousel from "react-bootstrap/Carousel";
import Container from "react-bootstrap/Container";
import HeroBackground1 from "../../images/hero-background-1.png";
import HeroBackground1Mobile from "../../images/hero-background-1-mobile.png";
import WhatWeLiveFor from "../../images/what-we-live-for.png";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import HeroBlindAngels from "../../images/homepage-blind-angels-hero.png";
import HeroBlindAngelsMobile from "../../images/homepage-blind-angels-hero-mobile.png";

class HeroCarousel extends Component {
   constructor() {
      super();
      this.state = {
         show: false,
      };
   }
   handleClose = (show) => {
      this.setState({ show: false });
   };
   handleClose = (show) => {
      this.setState({ show: false });
   };
   handleShow = (show) => {
      this.setState({ show: true });
   };
   render() {
      return (
         <Container className="px-0">
            <Row>
               <Col>
                  <Carousel interval={7000} keyboard={true}>

                     <Carousel.Item>
                        <img
                           className="d-none d-md-block w-100"
                           src={"/homepage-all-together-hero.jpg"}
                           alt="USCHA 2023"
                        />
                        <img
                           className="d-block d-md-none w-100"
                           src={"/homepage-all-together-hero-mobile.jpg"}
                           alt="USCHA 2023"
                        />

                        <Carousel.Caption className="all-together">
                           <Container>
                              <Row className="h-100">
                                 <Col
                                    xs={{ span: 6, offset: 9 }}
                                    md={{ span: 8, offset: 8 }}
                                    lg={{ span: 6, offset: 9 }}
                                    xl={{ span: 6, offset: 9 }}
                                    className="pl-3 pl-md-0 pl-xl-3"

                                 >
                                    <a className="no-interstitial nodecor">
                                       <div className="cont-slide pl-0">
                                          <h2 className="text-uppercase">
                                             We Are All in this together
                                          </h2>
                                          <p className="d-none d-lg-block">
                                             <span>We stand strong with the HIV community</span> to take on the challenges that are getting in the way of making progress. By working together with advocacy groups and community organizations, we’re committed to addressing these barriers and making meaningful change.
                                             <a
                                                className="slide-anchor ml-2"
                                                href="/about-us"
                                             >
                                                Learn more <span className="ml-2">&#8594;</span>
                                             </a>
                                          </p>
                                          <a
                                             className="slide-anchor ml-2 d-block d-lg-none"
                                             href="/about-us"
                                          >
                                             Learn More <span className="ml-2">&#8594;</span>
                                          </a>         
                                       </div>
                                    </a>
                                 </Col>
                              </Row>
                           </Container>
                        </Carousel.Caption>
                     </Carousel.Item>

                     <Carousel.Item>
                        <img
                           className="d-none d-md-block w-100"
                           src={"/homepage-toolkits-hero.jpg"}
                           alt="USCHA 2023"
                        />
                        <img
                           className="d-block d-md-none w-100"
                           src={"/homepage-toolkits-hero-mobile.jpg"}
                           alt="USCHA 2023"
                        />

                        <Carousel.Caption className="toolkit">
                           <Container>
                              <Row className="h-100">
                                 <Col
                                    xs={{ span: 8, offset: 7 }}
                                    md={{ span: 6, offset: 7 }}
                                    lg={{ span: 7, offset: 7 }}
                                    className="pl-3 pl-xl-5"

                                 >
                                    <a className="no-interstitial nodecor">
                                       <div className="cont-slide pl-0">
                                          <h2 className="text-uppercase mb-3">
                                             SOCIAL MEDIA <span>TOOLKITS</span>
                                          </h2>
                                          <p className="d-none d-lg-block">
                                             Help call attention to HIV awareness days throughout the year by building your own posts for Instagram, Facebook, and X with our free social media toolkits. <span>Download and post our free social media graphics in recognition of HIV awareness days throughout the year.</span>
                                          </p>

                                          <a
                                             className="slide-anchor"
                                             href="/community-involvement/social-toolkit"
                                          >
                                             Download free graphics <span className="ml-2">&#8594;</span>
                                          </a>
                                       </div>
                                    </a>
                                 </Col>
                              </Row>
                           </Container>
                        </Carousel.Caption>
                     </Carousel.Item>
                     <Carousel.Item>
                        <img
                           className="d-none d-md-block w-100"
                           src={"/putting-into-practice-hero.png"}
                           alt="USCHA 2023"
                        />
                        <img
                           className="d-block d-md-none w-100"
                           src={"/putting-into-practice-hero-mobile.png"}
                           alt="USCHA 2023"
                        />

                        <Carousel.Caption className="prep-matters">
                           <Container>
                              <Row className="h-100">
                                 <Col
                                    xs={{ span: 7 }}
                                    sm={{ span: 8 }}
                                    md={{ span: 6, offset: 1 }}

                                    className="pl-2 px-md-0"

                                 >
                                    <a className="no-interstitial nodecor">
                                       <div className="cont-slide">
                                          <h2 className="font-heavy line-height-25-mob d-none d-lg-block">
                                             PrEP Matters: <span className="no-break">A Guide</span> to Advancing Uptake
                                          </h2>
                                          <h2 className="font-heavy line-height-25-mob d-lg-none">
                                             PrEP Matters: A Guide to Advancing Uptake for Allied Healthcare Professionals
                                          </h2>

                                          <p className="d-none d-lg-block">
                                             Explore this downloadable resource for allied healthcare professionals on advancing <span className="no-break">pre-exposure</span> prophylaxis (PrEP) uptake at the moment of HIV and STI testing.
                                          </p>


                                          <a
                                             className="slide-anchor open_isi"
                                             href="/downloadable-resource/prep-basics"
                                          >
                                             <span className="btn red text-uppercase">
                                                Learn More
                                             </span>
                                          </a>
                                       </div>
                                    </a>
                                 </Col>
                              </Row>
                           </Container>
                        </Carousel.Caption>
                     </Carousel.Item>



                  </Carousel>
               </Col>
            </Row>
         </Container>
      );
   }
}

export default HeroCarousel;