import * as React from "react";
import { Component } from "react";
import GrayscaleHistory from "../../images/history-of-hiv-section-hero.jpg";
import GrayscaleHistoryMobile from "../../images/history-of-hiv-section-hero-mobile.png";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import HTML5Video from "../global/video.js";
import VideoSrc from "../../video/history.mp4";

class HistoryOfHIV extends Component {
  constructor() {
    super();
    this.state = {
      show: false,
    };
  }
  handleClose = (show) => {
    this.setState({ show: false });
  };
  handleShow = (show) => {
    this.setState({ show: true });
  };
  render() {
    return (
      <>
        <Container className="section">
          <Row>
            <Col xs={10} className="m-auto">
              <h2 className="title">History of HIV</h2>
              <Container className="history-of-hiv">
                <Row>
                  <Col
                    xs={12}
                    lg={4}
                    className="px-0 d-flex align-items-center order-1 order-lg-0"
                  >
                    <HTML5Video
                      handleClose={this.handleClose}
                      handleShow={this.handleShow}
                      show={this.state.show}
                      thumbnail={GrayscaleHistoryMobile}
                      url={this.state.show ? VideoSrc : ""}
                      videoTrackLabel="Homepage History of HIV"
                    />
                    <div className="content muted-yellow gtm-track-video-overlay">
                      <div className="cont-text">
                        <h3 className="title border-0">
                          Progress in the face of challenges
                        </h3>
                        <p className="font-light">
                          Watch this video to learn about the key moments and
                          leaders that have defined the course of the HIV
                          epidemic. Through a combination of both scientific
                          advancements and community activism, significant
                          progress has been made toward helping end the
                          epidemic. The work is far from over, but there is hope
                          and cause for celebration in the progress that's been
                          made.
                        </p>
                        <span className="watch">
                          <a
                            onClick={this.handleShow}
                            className="no-interstitial"
                          >
                            <h3 className="font-inherit d-inline letter-spacing-normal text-transform-none">
                              Watch the History of HIV video
                            </h3>
                            <i className="fa fa-chevron-right fa-inline"></i>
                          </a>
                        </span>
                      </div>
                    </div>
                  </Col>
                  <Col xs={12} lg={8} className="px-0 order-0 order-lg-1">
                    <div
                      onClick={this.handleShow}
                      className="play-button-overlay"
                    ></div>
                    <img
                      src={GrayscaleHistory}
                      className="d-none d-md-block image-underlay "
                      alt="Play the history of HIV"
                    />
                    <img
                      src={GrayscaleHistoryMobile}
                      className="d-block d-md-none image-underlay mb-2"
                      alt="Play the history of HIV"
                    />
                  </Col>
                </Row>
              </Container>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default HistoryOfHIV;
