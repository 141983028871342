import * as React from "react";
import HIVLandscapeIMG from "../../images/hiv-landscape-section-hero.png";
import HIVLandscapeIMGMobile from "../../images/hiv-landscape-section-hero-mobile.png";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const HIVLandscape = () => {
   return (
      <Container className="section hiv-landscape">
         <Row>
            <Col xs={10} className="m-auto">
               <h2 className="title">HIV landscape</h2>
               <Container>
                  <Row className="turquoise">
                     <Col className="py-md-4 px-0 pl-md-4" md={6}>
                        <img
                           className="featured-image d-none d-md-block"
                           src={HIVLandscapeIMG}
                           alt="Group of smiling people"
                        />
                        <img
                           className="featured-image d-block d-md-none mobile"
                           src={HIVLandscapeIMGMobile}
                           alt="Group of smiling people"
                        />
                     </Col>
                     <Col className="py-4 align-self-center" md={6}>
                        <div>
                           <p className="text-md-left font-light">
                              The HIV epidemic is a complex collection of{" "}
                              <span className="d-inline-block">microepidemics</span>{" "}
                              informed by history and driven by concrete goals for the
                              future.{" "}
                              <span className="d-inline-block d-md-inline">
                                 Explore the areas below for a foundational look at HIV.
                              </span>
                           </p>
                           <span className="tags text-center text-md-left">
                              <a
                                 href="/landscape/history-of-hiv"
                                 className="btn width-100-mobile"
                              /* data-analytics-event-category="Internal Links"
                              data-analytics-event-label="HIV Landscape Homepage Button" */
                              >
                                 <span>
                                    History of the
                                    <br className="d-md-none" /> hiv epidemic
                                 </span>
                              </a>
                              <a
                                 href="/landscape/state-of-epidemic"
                                 className="btn width-100-mobile"
                              /* data-analytics-event-category="Internal Links"
                              data-analytics-event-label="State of the Epidemic Homepage Button" */
                              >
                                 <span>
                                    State of the
                                    <br className="d-md-none" /> HIV Epidemic
                                 </span>
                              </a>
                              <a
                                 href="/landscape/ehe"
                                 className="btn width-100-mobile"
                              /* data-analytics-event-category="Internal Links"
                              data-analytics-event-label="Plans for EHE Homepage Button" */
                              >
                                 <span>
                                    Plans for Ending
                                    <br className="d-md-none" /> the HIV Epidemic
                                 </span>
                              </a>
                              <br />
                              <a
                                 href="/landscape/hiv-care-continuum"
                                 className="btn width-100-mobile"
                              /* data-analytics-event-category="Internal Links"
                              data-analytics-event-label="Care Continuum Homepage Button" */
                              >
                                 HIV Care
                                 <br className="d-md-none" /> Continuum
                              </a>
                              <a
                                 href="/landscape/hiv-testing"
                                 className="btn width-100-mobile"
                              /* data-analytics-event-category="Internal Links"
                              data-analytics-event-label="HIV Testing Homepage Button" */
                              >
                                 HIV Testing
                              </a>
                              <a
                                 href="/landscape/hiv-rapid-start"
                                 className="btn width-100-mobile"
                              /* data-analytics-event-category="Internal Links"
                              data-analytics-event-label="HIV Testing Homepage Button" */
                              >
                                 RAPID START INITIATION
                              </a>
                           </span>
                        </div>
                     </Col>
                  </Row>
               </Container>
            </Col>
         </Row>
      </Container>
   );
};

export default HIVLandscape;
